import * as React from "react"
import { graphql } from 'gatsby'

import Layout from "../../components/Layout";
import useCommonStyles from '../../assets/jss/commonStyles';
// import Seo from "../../components/Seo"

import AssetForm from './form';
import Helper from '@justnice/helper';
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { connect } from 'react-redux';
import * as systemActions from '../../redux/actions/systemActions';
import * as galleryActions from '../../redux/actions/galleryActions';

const UploadPage : React.FC = (props) => {
  const {t} = useTranslation('gallery-upload');
  const classes = useCommonStyles();
  
  const locale = props['ui'].get('lang');
  const tags = Helper.getStateAsJSObj(props['system'], 'tag.data', []);
  const sysList = Helper.getStateAsJSObj(props['system'], 'sysList.data', []);
  
  React.useEffect(() => {
    const tagObj = Helper.getStateAsJSObj(props['system'], 'tag', null);
    const sysListObj = Helper.getStateAsJSObj(props['system'], 'sysList', null);
    // If no tag info and tag is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(tagObj) || (!Helper.arrayHasItem(tagObj?.data) && !tagObj?.loading)) {
      props.dispatch(
        systemActions.tagGetList()
      );
    }

    // If no system list info and system list is not in loading, load it.
    if(!Helper.isNotNullAndUndefined(sysListObj) || 
       (!Helper.arrayHasItem(sysListObj?.data) && !sysListObj?.loading) ||
       (sysListObj?.locale !== locale && !sysListObj?.loading)) {
      props.dispatch(
        systemActions.sysListGet(locale)
      );
    }

  }, []);

  
  const onCancel = () => {
    // console.log('Asset form cancelled.');
  }

  const onDiscard = () => {
  // console.log('Asset form discarded.');
  }

  const onUpload = (error, data) => {
    if(!error) {
      props.dispatch(
        galleryActions.ownerPhotoInsertItem(data)
      );
    } 
  }

  const onDelete = (id) => {
    // console.log('Asset form deleted: ', id);
  }
  
  return (
    <Layout title={t('pageTitle')}>
      {/* <Seo title={t('title')} /> */}
      <AssetForm classes={classes} tags={tags} sysList={sysList} 
        onCancel={onCancel}
        onDiscard={onDiscard}
        onUpload={onUpload}
        onDelete={onDelete}
      />
    </Layout>
  );
}

export default connect((state) => {
  return {
    ui: state['ui'],
    session: state['session'],
    system: state['system'],
    page: state['page']
  };
})(UploadPage);

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
