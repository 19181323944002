import {actionType} from './actionType';
import Api from '../../lib/api';
import Helper from '@justnice/helper';

export function ownerPhotoGetList(query, callback) {
  return function(dispatch) {
    dispatch({type: actionType.gallery.ownerPhotoGetListPending, payload: null});
    
    Api.assetOwnerListGet(query)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.gallery.ownerPhotoGetListFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.gallery.ownerPhotoGetListRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.gallery.ownerPhotoGetListRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function ownerPhotoInsertItem(asset) {
  return {
    type: actionType.gallery.ownerPhotoInsertItem,
    payload: asset
  }
}

export function ownerPhotoUpdateItem(asset) {
  return {
    type: actionType.gallery.ownerPhotoUpdateItem,
    payload: asset
  }
}

export function ownerPhotoDeleteItem(id) {
  return {
    type: actionType.gallery.ownerPhotoDeleteItem,
    payload: id
  }
}

export function showCasePhotoGetList(query, page, callback) {
  return function(dispatch) {
    dispatch({type: actionType.gallery.showCasePhotoGetListPending, payload: null});
    
    Api.showCasePhotoListGet(query, page)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.gallery.showCasePhotoGetListFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.gallery.showCasePhotoGetListRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.gallery.showCasePhotoGetListRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}

export function showCasePhotoGetItem(id, callback) {
  return function(dispatch) {
    dispatch({type: actionType.gallery.showCasePhotoGetItemPending, payload: null});
    
    Api.showCasePhotoItemGet(id)
    .then(result => {
      if(result && result.status === 200 && result.data) {
        dispatch({
          type: actionType.gallery.showCasePhotoGetItemFulfilled,
          payload: result.data
        });
        callback && callback(true, result);
      }
      else {
        dispatch({
          type: actionType.gallery.showCasePhotoGetItemRejected,
          payload: Helper.getAxiosError(result)
        });
        callback && callback(false, Helper.getAxiosError(result));
      }
    })
    .catch(err => {
      dispatch({
        type: actionType.gallery.showCasePhotoGetItemRejected,
        payload: Helper.getErrorMsg(err)
      });
      callback && callback(false, err);
    });
  }
}